/* -- ready -- */
$(function() {
  'use strict';

  linkslider();


  /* -- resize -- */
  $(window).resize(function() {
    linkslider();
  });

});


/* -- load -- */
$(window).on('load' , function() {
  var topMv = $('.p-top-mv video').get(0);

  if ($('.p-top-mv video').length) {
    topMv.play();
  }
});


/* function */
// linkslider
function linkslider() {
  var deviceWidth = window.innerWidth;

  if (deviceWidth < 768) {
    $('.p-top-link').not('.slick-initialized').slick();
  } else {
    $('.p-top-link.slick-initialized').slick('unslick');
  }
}