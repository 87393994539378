/* -- ready -- */
$(function() {
  'use strict';

  graph($('.p-achievements__graph__bar'));
  greeting($('.p-greeting__img li'));
  interviewSlider();
  countUp($('.is-animated .is-counter01'), 10);
  countUp($('.is-animated .is-counter01-02'), 80);
  // delaystart
  $('.is-animated .is-counter02').delay(1000).queue(function() {
    countUp($(this), 10);
  });
  // delaystart
  $('.is-animated .is-counter02-02').delay(1000).queue(function() {
    countUp($(this), 80);
  });
  countUp($('.is-animated .is-counter03'), 5);
  // delaystart
  $('.is-animated .is-counter04').delay(1000).queue(function() {
    countUp($(this), 20);
  });
  // delaystart
  $('.is-animated .is-counter04-02').delay(1000).queue(function() {
    countUp($(this), 80);
  });

  fadeOrder($('.p-number__from__list, .p-number__bottom'));

  // interview-sp
  $('.p-efforts__interview__body__acc').click(function() {
    var deviceWidth = window.innerWidth;

    if (deviceWidth < 768) {
      var defaultHeight = $(this).prev().children().eq(0).outerHeight() + 40;
      var slideHeight = 0;

      $(this).prev().find('.p-efforts__interview__body__slide__item').each(function() {
        slideHeight = slideHeight + $(this).outerHeight() + 40;
      });

      if (!$(this).hasClass('is-open')) {
        $(this).addClass('is-open');
        $(this).prev().animate({'height': slideHeight}, 300);
      } else {
        $(this).removeClass('is-open');
        $(this).prev().animate({'height': defaultHeight}, 300);

        var basePosition = $(this).closest('.p-efforts__interview__body').offset().top - 62;
        console.log(basePosition);
        $('html, body').animate({scrollTop: basePosition}, 300);
      }
    }
  });

  // contact
  $('input[name="tel[data][0]"]').attr('placeholder', '03');
  $('input[name="tel[data][1]"]').attr('placeholder', '6772');
  $('input[name="tel[data][2]"]').attr('placeholder', '9090');


  /* -- scroll -- */
  $(window).scroll(function() {
    graph($('.p-achievements__graph__bar'));
    greeting($('.p-greeting__img li'));
    countUp($('.is-animated .is-counter01'), 10);
    countUp($('.is-animated .is-counter01-02'), 80);
    // delaystart
    $('.is-animated .is-counter02').delay(1000).queue(function() {
      countUp($(this), 10);
    });
    // delaystart
    $('.is-animated .is-counter02-02').delay(1000).queue(function() {
      countUp($(this), 80);
    });
    countUp($('.is-animated .is-counter03'), 5);
    // delaystart
    $('.is-animated .is-counter04').delay(1000).queue(function() {
      countUp($(this), 20);
    });
    // delaystart
    $('.is-animated .is-counter04-02').delay(1000).queue(function() {
      countUp($(this), 80);
    });

    fadeOrder($('.p-number__from__list, .p-number__bottom'));
  });


  /* -- resize -- */
  $(window).resize(function() {
    var deviceWidth = window.innerWidth;

    interviewSlider();

    if (deviceWidth > 767) {
      // reset
      $('.p-efforts__interview__body__acc').removeClass('is-open');
    }
  });
});


/* function */
// graph
function graph(elm) {
  elm.each(function() {
    var basePosition = $(this).offset().top;
    var scrollPosition = $(window).scrollTop();
    var deviceHeight = $(window).height();
    if (scrollPosition > basePosition - deviceHeight * .5) {
      $(this).removeClass('is-noanimated');
    }
  });
}

// greeting
function greeting(elm) {
  elm.each(function() {
    var basePosition = $(this).offset().top;
    var scrollPosition = $(window).scrollTop();
    var deviceHeight = $(window).height();
    if (scrollPosition > basePosition - deviceHeight * .5) {
      $(this).addClass('is-fadein');
      $(this).siblings().removeClass('is-fadein');
    }
  });
}

// countUp
function countUp(elm, speed) {
  elm.each(function() {
    var rate = $(this),
    countMax = rate.attr('data-num'),
    count = rate.text(),
    countTimer;

    function timer() {
      countTimer = setInterval(function(){
        var counter = count++;
        rate.text(counter);

        if (counter == countMax) {
          clearInterval(countTimer);
        }
      }, speed);
    }
    timer();
  });
}

// fadeOrder
function fadeOrder(elm) {
  elm.each(function() {
    var basePosition = $(this).offset().top;
    var scrollPosition = $(window).scrollTop();
    var deviceHeight = $(window).height();
    if (scrollPosition > basePosition - deviceHeight * .7) {
      $(this).children().addClass('is-animated');
    }
  });
}

// interviewSlider
function interviewSlider() {
  var deviceWidth = window.innerWidth;

  if (deviceWidth < 768) {
    $('.p-efforts__interview__body__slide.slick-initialized').slick('unslick');

    $('.p-efforts__interview__body__slide').each(function() {
      if (!$(this).next().hasClass('is-open')) {
          var defaultHeight = $(this).children().eq(0).outerHeight() + 40;

          $(this).css('height',defaultHeight);
      } else {
        var slideHeight = 0;

        $(this).find('.p-efforts__interview__body__slide__item').each(function() {
          slideHeight = slideHeight + $(this).outerHeight() + 40;
        });

        $(this).css('height',slideHeight);
      }
    });
  } else {
    $('.p-efforts__interview__body__slide').not('.slick-initialized').slick({
      infinite: false
    });
  }
}