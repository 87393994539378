/* -- ready -- */
$(function() {
  'use strict';

  /* スムーススクロール */
  $('a[href^="#"]').on('click', function() {
    // スピード（ミリ秒）
    var speed = 300;
    // アンカーの値取得
    var href = $(this).attr('href');
    // 移動先を取得
    var target = $(href == "#" || href == "" ? 'html' : href);
    // 移動先を座標で取得
    var position = target.offset().top;
    // アニメーション
    $('html, body').not(':animated').animate({scrollTop:position}, speed, 'swing');
    return false;
  });

  naviCurrent($('.l-gnavi__list > li'));
  fadeBlock($('.l-fadeblock > *, .p-page-slide-area li, .p-history__before__list li'));

  // gnavi
  $('.l-gnavi__list > li').hover(function() {
    var deviceWidth = window.innerWidth;

    if (deviceWidth > 767) {
      $(this).addClass('is-mnavi');
    }
  }, function() {
    var deviceWidth = window.innerWidth;

    if (deviceWidth > 767) {
      $(this).removeClass('is-mnavi');
    }
  });

  /* sp-gnavi */
  $('.l-header__menu').click(function() {
    var deviceWidth = window.innerWidth;

    if (deviceWidth < 768) {
      if (!$(this).hasClass('is-active')) {
        $(this).addClass('is-active');
        $('.l-gnavi').stop().slideDown();
      } else {
        $(this).removeClass('is-active');
        $('.l-gnavi').stop().slideUp();
      }
    }
  });

  /* sp-mnavi */
  $('.l-gnavi__list__acc').click(function() {
    var deviceWidth = window.innerWidth;

    if (deviceWidth < 768) {
      if (!$(this).hasClass('is-open')) {
        $(this).addClass('is-open');
        $(this).next().stop().slideDown();
      } else {
        $(this).removeClass('is-open');
        $(this).next().stop().slideUp();
      }
    }
  });

  /* search */
  $('.l-header__search > button').click(function() {
    $(this).parent().toggleClass('is-show');
  });

  /* stickyfill */
  var sfElem01 = $('.l-gnavi__inner');
  Stickyfill.add(sfElem01);

  /* pageload */
  setTimeout(function(){
    $('body').addClass('is-visible');
  },100);

  /* 拠点紹介 */
  $('.p-base-intro__slide').slick({
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2
        }
      }
    ]
  });

  /* -- scroll -- */
  $(window).scroll(function() {
    fadeBlock($('.l-fadeblock > *, .p-page-slide-area li, .p-history__before__list li'));

    /* pagetop */
    if ($(this).scrollTop() > 80) {
      $('.l-pagetop').stop(true, false).fadeIn();
    } else {
      $('.l-pagetop').stop(true, false).fadeOut();
    }

    /* lnavi */
    if ($(this).scrollTop() > 180) {
      $('.l-lnavi').addClass('is-visible');
    } else {
      $('.l-lnavi').removeClass('is-visible');
    }
  });


  /* -- resize -- */
  $(window).resize(function() {
    var deviceWidth = window.innerWidth;

    if (deviceWidth > 767) {
      // reset
      $('.l-header__menu').removeClass('is-active');
      $('.l-gnavi').hide();
      $('.l-gnavi__list__acc').removeClass('is-open');
      $('.l-mnavi').hide();
      $('.l-mnavi').css('visibility','hidden');
    }
  });
});


/* -- load -- */
$(window).on('load' , function() {
  $('body').removeClass('is-preload');
});


/* function */
// naviCurrent
function naviCurrent(naviElm) {
  const currentUrl = location.pathname + location.hash;

  naviElm.each(function() {
    let link = $(this).children('a').attr('href');
    var matchLink = new RegExp('^' + link);

    if (link == currentUrl || currentUrl.match(matchLink)) {
      $(this).addClass('is-current');
    } else if (currentUrl.match(/^\/\info/)){
      naviElm.eq(4).addClass('is-current');
    }
  });
}

// fadeBlock
function fadeBlock(elm) {
  elm.each(function() {
    var basePosition = $(this).offset().top;
    var scrollPosition = $(window).scrollTop();
    var deviceHeight = $(window).height();
    if (scrollPosition > basePosition - deviceHeight * .7) {
      $(this).addClass('is-animated');
    }
  });
}